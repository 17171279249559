<template>
  <div class="send_advice">
    <back-step
      :goList="[{
        url: '/cases',
        name: $t('casesDetail.casesChildren.common.bl')
      }, {
        url: '/cases/detail',
        name: $t('casesDetail.casesChildren.common.blxq')
      }]"
      :currentTxt="$t('casesDetail.casesChildren.sendAdvice.fsyz')"
    />
    <div class="plan_head">
      <span class="plan_h_strong">{{$t('casesDetail.casesChildren.reviewPlan.blh')}}：{{casesInfo.caseNumber}}</span>
      <span class="plan_h_sp">{{casesInfo.clinicName}}</span>
      <span class="plan_h_sp">{{casesInfo.productName}}</span>
    </div>
    <div class="braces_box">
      <div class="braces_left">
        <div class="braces_l_head">
          <div
            class="b_l_h_pic"
            :style="{backgroundImage: `url('${casesInfo.photo ? $PicPrefix + casesInfo.photo : defPhoto}')`}"
          >
          </div>
          <div class="h_u_msg">
            <h5 class="u_m_h5">
              {{casesInfo.realName}}
              <span
                class="xb_icon"
                :style="{backgroundImage: `url('${casesInfo.sex === '1' ? BOY_ICON : GIRL_ICON}')`}"
              ></span>
            </h5>
            <p class="p__c_lc_p">
              <span class="r_sr_icon"></span>
              {{casesInfo.age}}（{{casesInfo.birthdayStr}}）
              <span class="r_m_icon"></span>
              {{casesInfo.mobile}}
            </p>
            <p class="p__c_lc_p">
              <span class="r_d_icon"></span>
              {{casesInfo.address}}
            </p>
          </div>
        </div>
        <ul class="braces_from">
          <li class="f_single">
            <div :style="language === 'en_US' ? 'width: 2rem;' :''" class="f_single_left">
              {{$t('casesDetail.casesChildren.sendAdvice.yzlx')}}:
            </div>
            <div class="f_single_right">
              <el-select style="width: 2.74rem;" v-model="msgType" clearable
                         :placeholder="$t('casesDetail.casesChildren.common.qxz')">
                <el-option
                  v-for="item in msgTypeList"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key">
                </el-option>
              </el-select>
            </div>
          </li>
          <li class="f_single" v-show="msgType === 'invite'">
            <div :style="language === 'en_US' ? 'width: 2rem;' :''" class="f_single_left">
              {{$t('casesDetail.casesChildren.sendAdvice.smsj')}}:
            </div>
            <div class="f_single_right">
              <el-date-picker
                v-model="meetDatetime"
                type="datetime"
                :placeholder="$t('casesDetail.casesChildren.common.xzrq')"
                style="width: 2.74rem;">
              </el-date-picker>
            </div>
          </li>
          <li class="f_single">
            <div :style="language === 'en_US' ? 'width: 2rem;' :''" class="f_single_left">
              {{$t('casesDetail.casesChildren.sendAdvice.yznr')}}:
            </div>
            <div class="f_single_right">
              <div class="right_area">
                <textarea v-model="msgForm.content"></textarea>
              </div>
            </div>
          </li>
        </ul>
        <div class="left_foo">
          <span class="main_theme_btn_gary main_small_btn " @click="goBack">{{$t('casesDetail.casesChildren.common.fh')}}</span>
          <span class="main_theme_color_btn main_small_btn ml20" @click="sendCasesMsg">{{$t('casesDetail.casesChildren.sendAdvice.fs')}}</span>
        </div>
      </div>
      <div class="braces_right">
        <h5 class="b_r_h5">
          <span class="b_r_line"></span>
          {{$t('casesDetail.casesChildren.sendAdvice.lsyz')}}
        </h5>
        <ul class="his_ul" v-if="cartMsg.length">
          <li
            :class="item.toUserId !== sendUserId ? 'his_doctor_single' : 'his_user_single'"
            v-for="item in cartMsg"
            :key="item.id"
          >
            <div class="his_left" v-if="item.toUserId !== sendUserId">
              <h5 class="his_tit">{{$t('casesDetail.casesChildren.sendAdvice.yznr')}}：{{refTypeObj[item.refType]}}</h5>
              <p class="meet_time" v-if="item.meetDatetime">{{$t('casesDetail.casesChildren.sendAdvice.smsj')}}：{{item.meetDatetime}}</p>
              <p class="his_con">
                {{item.content}}
              </p>
              <p class="his_time">{{item.createDatetime}}</p>
            </div>
            <div
              class="his_left"
              :style="{backgroundImage: `url('${item.fromUserPhoto? $PicPrefix + item.fromUserPhoto : defPhoto}')`}"
              v-else
            >
            </div>
            <div
              class="his_right"
              :style="{backgroundImage: `url('${item.fromUserPhoto ? $PicPrefix + item.fromUserPhoto : defPhoto}')`}"
              v-if="item.toUserId !== sendUserId"
            >
            </div>
            <div class="his_right" v-else>
              <p class="his_con">
                {{item.content}}
              </p>
              <p class="his_time">{{item.createDatetime}}</p>
            </div>
          </li>
        </ul>
        <div class="msg_no" v-else>
          {{$t('casesDetail.casesChildren.sendAdvice.zwyz')}}
        </div>
      </div>
    </div>
    <FullLoading v-show="isLoading"/>
  </div>
</template>

<script>
  import BackStep from '../childrenPublic/backStep';
  import FullLoading from 'components/full-loading/full-loading';
  import {addCaseMessage, caseChatList, addCaseInvite, casesDetail} from 'common/api/cases';
  import {getDictList} from 'common/api/public';
  import {notifyMsg, getUserId, formatDate} from 'common/js/util';
  import {defPhoto, BOY_ICON, GIRL_ICON} from 'common/js/requireImage';
  import {mapState} from 'vuex';

  export default {
    data() {
      return {
        defPhoto,
        BOY_ICON,
        GIRL_ICON,
        isLoading: false,
        casesInfo: {},
        msgType: '',
        meetDatetime: '',
        msgForm: {
          content: ''
        },
        cartMsg: [],
        caseId: '',
        msgTypeList: [],
        refTypeObj: {},
        sendUserId: getUserId(),
        hisUlEle: null
      }
    },
    computed:{
      ...mapState({
        language: state => state.language,
      }),
      routeQueryCaseId(){
        return this.$route.query.caseId
      },
      routeQueryCurePlanId(){
        return this.$route.query.curePlanId
      }
    },
    methods: {
      dealCasesData(data) {
        const {productName, clinicId, clinicName, caseNumber} = data;
        const {realName, photo, address = {}, age, birthdayStr, mobile, sex} = data.userInfo;
        const {provinceName = '', cityName = '', areaName = ''} = address;
        this.casesInfo = {
          caseNumber,
          clinicName,
          clinicId,
          productName,
          realName,
          photo,
          address: `${provinceName}-${cityName}-${areaName}-${address.address || ''}`,
          age,
          sex,
          birthdayStr,
          mobile
        };
      },
      caseChatList() {
        caseChatList({caseId: this.caseId, sort: 'createDatetime'}).then(data => {
          this.isLoading = false;
          this.cartMsg = data;
        }).catch(() => {
          this.isLoading = false;
        });
      },
      sendCasesMsg() {
        const {content} = this.msgForm;
        const isInvite = this.msgType === 'invite';
        if(!this.msgType) {
          return notifyMsg(this, 'warning', this.$t('casesDetail.casesChildren.sendAdvice.qxzyzlx'), 2500);
        }else if(isInvite && !this.meetDatetime) {
          return notifyMsg(this, 'warning', this.$t('casesDetail.casesChildren.sendAdvice.qxzsmsj'), 2500);
        }else if(!content) {
          return notifyMsg(this, 'warning', this.$t('casesDetail.casesChildren.sendAdvice.qsryznr'), 2500);
        }
        const sendApi = isInvite ? addCaseInvite : addCaseMessage;
        const obj = {
          caseId: this.caseId,
          ...this.msgForm
        };
        if(isInvite) {
          obj.meetDatetime = formatDate(this.meetDatetime);
        }
        this.isLoading = true;
        sendApi(obj).then(() => {
          this.isLoading = false;
          notifyMsg(this, 'success', this.$t('casesDetail.casesChildren.sendAdvice.fscg'), 2500);
          this.caseChatList();
          this.msgType = '';
          this.meetDatetime = '';
          this.msgForm = {
            content: ''
          };
        }).catch(() => {
          this.isLoading = false;
        });
      },
      goBack() {
        window.history.go(-1);
      },
      goPage(url) {
        this.$router.push(url);
      }
    },
    components: {
      BackStep,
      FullLoading
    },
    beforeDestroy() {
      if(this.hisUlEle) {
        this.hisUlEle = null;
      }
    },
    created() {
      document.title = this.$t('casesDetail.casesChildren.sendAdvice.fsyz');
      this.caseId = this.routeQueryCaseId;
      casesDetail(this.caseId).then(data => {
        this.dealCasesData(data);
      });
      this.isLoading = true;
      this.caseChatList();
      getDictList('doctorCaseMessage.type').then(data => {
        const obj = {};
        this.msgTypeList = data.map(item => {
          obj[item.key] = item.value;
          return {
            key: item.key,
            value: item.value
          }
        });
        this.refTypeObj = obj;
      });
    },
    mounted() {
      setTimeout(() => {
        this.hisUlEle = document.querySelector('.his_ul');
        if(this.hisUlEle) {
          const y = this.hisUlEle.scrollHeight;
          this.hisUlEle.scrollTo(0, y);
        }
      }, 500);
    },
  }
</script>

<style scoped lang="scss">
  .send_advice{
    width: 13.44rem;
    margin: 0 auto;
    color: $main_theme_color_333;
    font-size: 0.16rem;
    .plan_head{
      display: flex;
      align-items: center;
      margin-bottom: 0.2rem;
      .plan_h_strong{
        font-size: 0.22rem;
        font-weight: bold;
      }
      .plan_h_sp{
        font-size: 0.18rem;
        padding-left: 0.1rem;
        border-left: 0.01rem solid #B5B5B5;
        margin-left: 0.3rem;
      }
    }
    .braces_box{
      display: flex;
      .braces_left{
        background-color: #fff;
        border-radius: 0.08rem;
        flex: 1;
        padding: 0.4rem 0.7rem 0.5rem 0.29rem;
        .braces_l_head{
          display: flex;
          align-items: center;
          margin-bottom: 0.36rem;
          .b_l_h_pic{
            min-width: 1.02rem;
            width: 1.02rem;
            height: 1.02rem;
            border-radius: 100%;
            margin-right: 0.3rem;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
          }
          .h_u_msg{
            .u_m_h5{
              font-size: 0.22rem;
              margin-bottom: 0.24rem;
              display: flex;
              align-items: center;
              .xb_icon{
                display: inline-block;
                width: 0.18rem;
                height: 0.18rem;
                margin-left: 0.08rem;
                background-size: 100% 100%;
              }
            }
            .p__c_lc_p{
              display: flex;
              line-height: 0.24rem;
              color: #666;
              &:nth-of-type(1){
                margin-bottom: 0.2rem;
              }
            }
          }
        }
        .braces_from {
          margin-bottom: 0.2rem;
          .f_single {
            display: flex;
            align-items: center;
            margin-bottom: 0.2rem;
            .f_single_left {
              margin-right: 0.22rem;
              font-size: 0.16rem;
              width: 0.92rem;
              text-align: right;
              span {
                color: #FF6A08;
              }
            }
            .f_single_right {
              border-radius: 0.02rem;
              min-height: 0.42rem;
              color: #333;
              font-size: 0.14rem;
              display: flex;
              align-items: center;
              flex: 1;
              select {
                width: 2.74rem;
                height: 0.42rem;
                border: none;
                background-color: #F4F4F4;
                padding: 0.1rem 0.19rem;
                box-sizing: border-box;
              }
              .right_area{
                background-color: #F4F4F4;
                padding: 0.1rem;
                height: 1.14rem;
                border-radius: 0.08rem;
                width: 5.6rem;
                textarea{
                  width: 100%;
                  height: 100%;
                  border: none;
                  outline: none;
                }
              }
            }
          }
        }
        .left_foo{
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
      }
      .braces_right{
        margin-left: 0.2rem;
        width: 4.6rem;
        background-color: #fff;
        border-radius: 0.06rem;
        padding: 0.3rem;
        .b_r_h5{
          font-size: 0.22rem;
          display: flex;
          align-items: center;
          margin-bottom: 0.2rem;
          .b_r_line{
            display: inline-block;
            width: 0.03rem;
            height: 0.22rem;
            background-color: $main_theme_color;
            margin-right: 0.1rem;
          }
        }
        .his_ul{
          height: 4rem;
          overflow-y: scroll;
          padding-top: 0.34rem;
          font-family:Microsoft YaHei;
          .his_doctor_single{
            display: flex;
            margin-bottom: 0.3rem;
            justify-content: flex-end;
            .his_left{
              padding: 0.18rem 0.26rem 0.15rem;
              background-color: #F6F6F6;
              font-size: 0.14rem;
              margin-right: 0.2rem;
              border-radius: 0.06rem;
              box-sizing: border-box;
            }
            .his_right{
              width: 0.6rem;
              height: 0.6rem;
              border-radius: 100%;
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;
            }
          }
          .his_user_single{
            display: flex;
            margin-bottom: 0.3rem;
            .his_left{
              margin-right: 0.2rem;
              width: 0.6rem;
              height: 0.6rem;
              border-radius: 100%;
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;
            }
            .his_right{
              padding: 0.18rem 0.26rem 0.15rem;
              background-color: #F6F6F6;
              font-size: 0.14rem;
              border-radius: 0.06rem;
              box-sizing: border-box;
            }
          }
          .his_tit{
            color: #333333;
            margin-bottom: 0.08rem;
          }
          .meet_time{
            white-space: nowrap;
            color: #333;
            font-size: 0.12rem;
            margin-bottom: 0.1rem;
          }
          .his_con{
            color: #666666;
            line-height: 0.2rem;
            margin-bottom: 0.15rem;
          }
          .his_time{
            color: #999999;
            font-size: 0.12rem;
          }
        }
        .msg_no{
          color: #666;
          font-size: 0.2rem;
          letter-spacing: 0.01rem;
          padding-top: 1.5rem;
          text-align: center;
        }
      }
    }
  }
</style>
